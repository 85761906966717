import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

class Children {
  id: number;
  gender: number; //0 - male; 1 - frmale
  name: string;
  donated: number;
  balance: number;
}

class Waste {
  amount: number;
  name: string;
  dt: string;
  file: string;
}

class News {
  dt: string;
  content: string;
  image: string;
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  Title = 'ДОУ №29, группа ';
  BaseUrl = 'https://dou29.sposad.ru/data/';
  SelectedTab = 0;
  Key: string;

  Kids: Children[];
  Wasted: Waste[];
  News: News[];
  Balance: number;
  TotalWasted: number;
  TotalDonated: number;

  constructor(private http: HttpClient, private router: Router) {
    var q = window.location.href.split('/');
    this.Key = q[3];
    this.BaseUrl += this.Key;
    this.Title += this.Key.substr(1, 1);
    if (q[4] != null) {
      this.SelectedTab = Number.parseInt(q[4]);
    }
  }

  async ngOnInit() {
    try {
      this.Kids = await this.http
        .get<Children[]>(this.BaseUrl + '-kids.json')
        .toPromise();
      this.Wasted = await this.http
        .get<Waste[]>(this.BaseUrl + '-wasted.json')
        .toPromise();
    } catch {
      this.Title = 'Ошибка авторизации';
    }

    this.TotalWasted = 0;
    this.TotalDonated = 0;
    for (var i = 0; i < this.Wasted.length; i++) {
      this.TotalWasted += this.Wasted[i].amount;
    }

    for (var i = 0; i < this.Kids.length; i++) {
      this.TotalDonated += this.Kids[i].donated;
    }

    this.Balance = this.TotalDonated - this.TotalWasted;

    this.http
      .get<News[]>(this.BaseUrl + '-news.json')
      .subscribe((r) => (this.News = r));
  }

  TabChanged(e) {
    if (e.index > 0) this.router.navigate([this.Key, e.index]);
    else this.router.navigate([this.Key]);
  }
}
