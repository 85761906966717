<mat-toolbar color="primary">
  <span>{{Title}}</span>
  <span class="spacer"></span>
  <span> {{Balance?.toFixed(2)}} руб.</span>
</mat-toolbar>

<mat-tab-group mat-align-tabs="center"
               [selectedIndex]="SelectedTab"
               (selectedTabChange)="TabChanged($event)">
  <mat-tab>
    <ng-template mat-tab-label>
      <mat-icon>group</mat-icon>
    </ng-template>
    <mat-card>
      <h2>Список детей</h2>
    </mat-card>
    <mat-table [dataSource]="Kids">
      <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef> Имя ребенка </mat-header-cell>
        <mat-cell *matCellDef="let row"> {{row.name}} </mat-cell>
      </ng-container>
      <ng-container matColumnDef="donated">
        <mat-header-cell *matHeaderCellDef> Внесено </mat-header-cell>
        <mat-cell *matCellDef="let row"> {{row.donated.toFixed(2)}} </mat-cell>
      </ng-container>
      <mat-header-row *matHeaderRowDef="['name', 'donated']"></mat-header-row>
      <mat-row *matRowDef="let row; columns: ['name', 'donated']"></mat-row>
    </mat-table>
    <mat-card>
      <h2>Всего детей: {{Kids.length}}</h2>
      <h2>Внесено: {{TotalDonated.toFixed(2)}} руб.</h2>
    </mat-card>
  </mat-tab>

  <mat-tab>
    <ng-template mat-tab-label>
      <mat-icon>shopping_cart</mat-icon>
    </ng-template>
    <mat-card>
      <h2>Список закупок</h2>
    </mat-card>
    <mat-table [dataSource]="Wasted">
      <ng-container matColumnDef="name">
        <mat-header-cell *matHeaderCellDef> Закупка </mat-header-cell>
        <mat-cell *matCellDef="let row"> {{row.name}} </mat-cell>
      </ng-container>
      <ng-container matColumnDef="amount">
        <mat-header-cell *matHeaderCellDef> Стоимость </mat-header-cell>
        <mat-cell *matCellDef="let row"> {{row.amount.toFixed(2)}} </mat-cell>
      </ng-container>
      <ng-container matColumnDef="file">
        <mat-header-cell *matHeaderCellDef></mat-header-cell>
        <mat-cell *matCellDef="let row"><a href="{{row.file}}">
            <mat-icon>receipt</mat-icon>
          </a> </mat-cell>
      </ng-container>
      <ng-container matColumnDef="dt">
        <mat-header-cell *matHeaderCellDef> Дата </mat-header-cell>
        <mat-cell *matCellDef="let row"> {{row.dt}} </mat-cell>
      </ng-container>
      <mat-header-row *matHeaderRowDef="['name', 'amount', 'file', 'dt']"></mat-header-row>
      <mat-row *matRowDef="let row; columns: ['name', 'amount', 'file', 'dt']"></mat-row>
    </mat-table>
    <mat-card>
      <h2>Потрачено: {{TotalWasted.toFixed(2)}} руб.</h2>
    </mat-card>
  </mat-tab>

  <mat-tab>
    <ng-template mat-tab-label>
      <mat-icon>message</mat-icon>
    </ng-template>
    <mat-card>
      <h2>Новости</h2>
    </mat-card>
    <mat-card *ngFor="let row of News">
      <mat-card-title>{{row.dt}}</mat-card-title>
      <mat-card-content>
        <div innerHTML="{{row.content}}"></div>
      </mat-card-content>
      <img mat-card-image
           *ngIf="row.image != null"
           src="{{row.image}}">
    </mat-card>
  </mat-tab>

  <mat-tab>
    <ng-template mat-tab-label>
      <mat-icon>info</mat-icon>
    </ng-template>
    <mat-card>
      <h2>Информация</h2>
      <p>Фонд группы создан для совместных закупок, например, подарки воспитателям, подарки детям на новый год, нужды группы в течении года.</p>
      <p>Взнос в фонд можно сделать переводом на карту Сберкассы по телефону +7 926 2756433 (Алексей Вячеславович Т.) или наличными при встрече. При переводе, пожалуйста, указывайте в сообщении (назначение платежа) фамилию и имя ребенка, за которого делается взнос.</p>
      <p>В конце года, неизрасходованные средства будут возвращены родителям на карту Сберкассы.</p>
    </mat-card>
  </mat-tab>
</mat-tab-group>